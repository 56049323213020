import { postQrLogin } from 'api';
import { debounceSecond } from 'assets/constant';
import { SpinnerLoading } from 'components/common';
import { debounce } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

const QrType = {
  CAREER_1: 'KBU 건강복지 EXPO 부스 방문(야외)',
  CAREER_2: '커리어 페스티발 행정부스 방문하기(체육관)',
  CAREER_3: '커리어 페스티발 이벤트, 학과부스 방문하기(체육관)',
  FORUM: '대학혁신포럼 - 포럼 참여하기',
};

export function QrLoginPage() {
  const autoFocusInput = useRef(null);
  const [verification, setVerification] = useState(false);
  const [userId, setUserId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const 패스 = useLocation()?.pathname.replace('/qr/login/', '') || '';
  const debounceHandleSubmit = debounce(async () => {
    const loginData = {
      loginId: userId,
      qrType: 패스,
    };

    setIsLoading(true);
    try {
      const result = await postQrLogin(loginData);
      resetLogin();
      // 에러메세지를 반환하면 에러메세지 출력
      if (!result && !result.status !== 200) {
        setIsLoading(false);
        return;
      }
      if (result.data.errorCode && result.data.errorCode === 'U001') {
        setErrorMsg('학번을 확인해주세요');
        alert('학번을 확인해주세요');
        setIsLoading(false);
        return setVerification(true);
      }
      if (result.data.errorCode && result.data.errorCode === 'QR001') {
        setErrorMsg('이전에 출석한 내역이 있습니다.');
        setIsLoading(false);
        return setVerification(true);
      }
      if (result.data.errorCode && result.data.errorCode === 'QR002') {
        alert('출석기간이 종료되었습니다.');
        setIsLoading(false);
        return;
      }

      setVerification(false);
      setIsLoading(false);
      alert(result.data.message || '출석 확인이 완료되었습니다.');
      resetLogin();
    } catch (error) {
      console.error('error', error);
      setIsLoading(false);
    }
  }, debounceSecond);

  const onSubmit = (e) => {
    if (userId === '' || userId === null) {
      alert('학번을 입력해주세요.');
      return;
    }
    e.preventDefault();
    debounceHandleSubmit();
  };

  const resetLogin = () => {
    setUserId('');
  };

  useEffect(() => {
    autoFocusInput.current.focus();
    return () => {
      setVerification(null);
    };
  }, []);

  return (
    <>
      <main id='qrLoginPage'>
        <form onSubmit={onSubmit} className='login-align' style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <div className='d-flex justify-content-center'>
            <img src='/assets/img/login_logo.png' alt='' id='qr-login-img' />
          </div>
          <div id='qr-login-card-wrapper'>
            <label className='qr-main-title m-0 qr-point'>
              KBU 성공전략 <span className='qr-sub'>QR 출석</span>
            </label>
            <div className='card' id='qr-login-card'>
              <div className='col-12'>
                <div className='d-flex align-items-center mb-2'>
                  <label className='qr-login-title m-0'>출석</label>
                </div>
                <div className='d-flex align-items-center mb-2'>
                  <label className='qr-login-body qr-point m-0'>
                    {QrType[패스]}
                    {/* {qrInfo.number && ` (행사부스 ${qrInfo.number})`} */}
                  </label>
                </div>
              </div>
              <div className='qr-divider'></div>
              <div onSubmit={onSubmit} className='row g-3 needs-validation'>
                <div className='col-12'>
                  <div className='d-flex align-items-center mb-2'>
                    <label htmlFor='yourUsername' className='qr-login-title qr-primary m-0'>
                      학번
                    </label>
                    <p className='fz-12 text-center danger-redText ms-3'>{verification && errorMsg}</p>
                  </div>
                  <p className='qr-helper-text qr-secondary'>
                    출석체크를 위해 <span className='qr-point'>본인 학번</span>을 입력해주세요
                  </p>
                  <div className='input-group has-validation'>
                    <input
                      type='text'
                      name='username'
                      className='form-control'
                      id='yourUsername'
                      value={userId}
                      onChange={(e) => setUserId(e.target.value.trimStart())}
                      required
                      ref={autoFocusInput}
                      placeholder='학번을 입력해주세요'
                    />
                    <div className='invalid-feedback'>학번을 입력해주세요</div>
                  </div>
                </div>
              </div>
            </div>
            <button disabled={isLoading} className='w-100 qr-login-btn qr-login-btn' type='submit'>
              출석 확인
            </button>
          </div>
          <>{isLoading && <SpinnerLoading />}</>
        </form>
      </main>
    </>
  );
}
